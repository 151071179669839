<template>
    <div>
        <div class="row mb-3">
            <div class="col-6">
                <h2 class="text-dark">
                    Marcas
                </h2>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-success mr-1" @click="add()">
                    <font-awesome-icon icon="plus" /> Crear
                </button>
            </div>
		</div>
        <div class="table-responsive">
            <vuetable ref="vuetable"
                :fields="fields"
                :apiUrl="vueTableConfig.url"
                :per-page="vueTableConfig.perPage"
                :appendParams="vueTableConfig.appendParams"
                :css="vueTableConfig.css.table"
                @vuetable:pagination-data="onPaginationData"
                pagination-path=""
                :transform="transform"
            >
                <div slot="name-slot" slot-scope="item">
                    {{item.rowData.name}}
                </div>       
                <div slot="acciones-slot" slot-scope="item">
                    <b-dropdown id="dropdown-1" text="Acciones" variant="primary">
                        <router-link class="dropdown-item" :to="`marcas/editar/${item.rowData.id}`">
                            <font-awesome-icon icon="edit" /> Editar
                        </router-link>
                        <b-dropdown-divider></b-dropdown-divider>
                            <a class="dropdown-item" @click="remove(item.rowData)">
                                <font-awesome-icon icon="trash-alt" /> Eliminar
                            </a>
                    </b-dropdown>
                </div>
            </vuetable>
		</div>
        <div class="pt-1 pb-3 float-right">
            <vuetable-pagination ref="pagination"
                :css="vueTableConfig.css.pagination"
                @vuetable-pagination:change-page="onChangePage"
            />
            <vuetable-pagination-info ref="paginationInfo" 
                :css="vueTableConfig.info.css"
                :infoTemplate="vueTableConfig.info.infoTemplate"
                :noDataTemplate="vueTableConfig.info.noDataTemplate"
            />
        </div>
        <confirm ref="confirm"></confirm>
    </div>
</template>

<script>
import vueTableMixin from "@/mixins/VueTableMixin";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faEdit, faTrashAlt, faUserCog, faBook} from '@fortawesome/free-solid-svg-icons';

// import _ from "lodash";

import Confirm from '../../components/vue-confirm/Confirm.vue'

library.add([faPlus, faEdit, faTrashAlt, faUserCog, faBook]);

export default {
	name: 'Marcas',
    mixins: [
        vueTableMixin
    ],
    components: {
        Confirm
    },
	data: () => {
		return {
            fields: [
                {
                    title: 'Nombre',
                    name: "name-slot",
                    sortField: 'name'
                },
                {
                    title: '',
                    name: "acciones-slot"
                }
            ]
		}
	},
	methods: {
		add() {
			this.$router.push({path: 'marcas/nueva'});
		},
		remove ( item ) {
            let self = this;
            console.log('item to remove', item.id);

            self.$refs.confirm.show({
                html: true,
                message: `¿ Está seguro de eliminar la marca <b>${item.name}</b> del sistema ?`,
                okButton: 'Eliminar',
                callback: confirm => {
                    if (confirm) {
                        self.$refs.confirm.setLoading(true);
                        self.axios
                        .delete(`branches/${item.id}`)
                        .then(response => {
                            self.$refs.confirm.setLoading(false);
                            if(response.error) {
                                self.$toastr.e(response.message, "Error");
                            } else {
                                self.$toastr.s("Marca eliminada correctamente", "Correcto");
                                self.$refs.confirm.close();
                                self.refresh();
                            }
                        })
                        .catch(function (error) {
                            self.$refs.confirm.setLoading(false);
                            self.$toastr.e(error, "Exception");
                        });
                    }
                }
            });
		}
	},
	beforeMount: function() {
		let self = this;

        console.log('aaaa',  `${self.$apiAdress}branches`);
        self.vueTableConfig.url = `${self.$apiAdress}branches`;
	}
}
</script>
